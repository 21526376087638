/* eslint-disable */
export default function(ctx) {

  // console.log(ctx.$tuf('name'));

  return {
    offercollections: [
      {
        label: "Naam",
        name: "name",
        map: "name",
        type: "text"
      },

      {
        label: "Kies de weergave van het aanbod",
        name: "type",
        map: "type",
        type: "select",
        options: {
          map: "Interactive kaart",
          list: "Lijst"
        },
        placeholder:
          "Kies of je een lijst wilt tonen o.b.v. aanbod of een interactieve kaart."
      },

      {
        label: "Selecteer het aanbod",
        hint: "Selecteer assetgroepen waarvan je het aanbod wil tonen.",
        placeholder: "Selecteer 1 of meerdere assetgroepen",
        name: "assetgroups",
        map: "assetgroups",
        type: "multiselect",
        endpoint: "assetgroups",
        displayIf: {
          field: "type",
          comparison: "=",
          value: "list"
        }
      },

      {
        label: "Selecteer een interactieve kaart",
        hint:
          "Selecteer een kaart uit de Prasset aanzichten module die hier moet worden toegepast",
        placeholder: "Selecteer 1 of meerdere assetgroepen",
        name: "map",
        map: "map",
        type: "select",
        endpoint: "mapsets",
        displayIf: {
          field: "type",
          comparison: "=",
          value: "map"
        }
      },

      {
        label: "Status getoonde aanbod",
        hint:
          "Geef aan welke status het aanbod moet hebben om weer te geven in de widget.",
        name: "filters",
        map: "filters",
        type: "multiselect",
        placeholder: "Selecteer 1 of meerdere statussen",
        options: {
          AVAILABLE: "Beschikbaar",
          UNDER_OFFER: "Onder optie",
          UNDER_OPTION: "In optie",
          WITH_RESERVATION: "Gereserveerd",
          SOLD: "Verkocht",
          RENTED: "Verhuurd"
        },
        displayIf: {
          field: "type",
          comparison: "=",
          value: "list"
        }
      },

      {
        label: "Aanbod filters",
        name: "module_filters",
        map: "module_filters",
        type: "multiselect",
        hint: "Geef aan op welke waarden de gebruiker het aanbod kan filteren",
        options: {
          sleeping_rooms: "Slaapkamers",
          status: "Status",
          price: "Prijs",
          surface: "Oppervlakte",
          energy_label: "Energielabel",
          number_of_floors: "Verdiepingen",
          lot_surface: "Kaveloppervlakte",
          asset_volume: "Inhoud (volume)",
          parking_spaces: "Parkeerplaatsen"
        }
      },

      {
        label: "Selecteer een formulier",
        hint: "Selecteer een formulier die je aan het aanbod wilt koppelen.",
        placeholder: "Formulieren selecteren",
        name: "form",
        map: "form",
        type: "select",
        endpoint: "forms"
      }
    ]
  };
}
