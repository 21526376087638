<template>
  <div class="grid grid-cols-6 gap-6">
    <div v-if="mode === 'create'" class="col-span-6">
      <label for="input_form" class="text-sm text-prasset-green-800 mb-2 block">
        Formulier
      </label>
      <SelectField id="input_form" name="form" v-model="form.form" :value="form.form" :endpoint="`projects/${$route.params.project_id}/forms`" :error="validationErrors.form" />
      <div class="text-xs text-gray-600 mt-1">
        Deze notificatie wordt verstuurd bij het versturen van het geselecteerde formulier.
      </div>
    </div>
    <div class="col-span-6">
      <label for="input_subject" class="text-sm text-prasset-green-800 mb-2 block">
        Onderwerp van de notificatie
      </label>
      <TextField id="input_subject" name="subject" v-model="form.subject" :error="validationErrors.subject" />
      <div class="text-xs text-gray-600 mt-1">
        Gebruik van variabelen toegestaan.
      </div>
    </div>
    <div class="col-span-6">
      <label for="input_template" class="text-sm text-prasset-green-800 mb-2 block">
        Inhoud van de notificatie e-mail
      </label>
      <TextareaField id="input_template" name="template" v-model="form.template" :error="validationErrors.template" :rows="15" />
      <div class="text-xs text-gray-600 mt-1">
        Gebruik van variabelen toegestaan.
      </div>
    </div>
    <div v-if="variables" class="col-span-6 bg-gray-300 rounded p-4 text-xs text-prasset-gray-800 mt-1">
      <div class="font-medium text-prasset-gray-900 mb-2">Variabelen</div>
      Onderstaande variabelen zijn beschikbaar in velden waarbij is aangegeven dat ze gebruikt kunnen worden,
      variabelen worden bij het versturen van de notificatie vervangen voor ingevulde gegevens.
      <div class="mt-3 grid grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-1 border-t border-gray-400 pt-4">
        <div v-for="v in variables" :key="v" @click="copyToClipboard(v)" class="cursor-pointer hover:underline">{{ `\{\{${ v }\}\}` }}</div>
      </div>
    </div>
    <h2 class="col-span-6 text-xl font-semibold text-prasset-green-500">
      Verzender
    </h2>
    <div class="col-span-6 lg:col-span-2">
      <label for="input_sender_name" class="text-sm text-prasset-green-800 mb-2 block">
        Naam
      </label>
      <TextField id="input_sender_name" name="sender_name" v-model="form.sender_name" :error="validationErrors.sender_name" />
      <div class="text-xs text-gray-600 mt-1">
        Gebruik van variabelen toegestaan.
      </div>
    </div>
    <div class="col-span-6 lg:col-span-4">
      <label for="input_sender_email" class="text-sm text-prasset-green-800 mb-2 block">
        E-mailadres
      </label>
      <TextField id="input_sender_email" name="sender_email" v-model="form.sender_email" :error="validationErrors.sender_email" />
      <div class="text-xs text-gray-600 mt-1">
        Gebruik van variabelen toegestaan.
      </div>
    </div>
    <h2 class="col-span-6 text-xl font-semibold text-prasset-green-500">
      Ontvanger
    </h2>
    <div class="col-span-6 lg:col-span-2">
      <label for="input_receiver_name" class="text-sm text-prasset-green-800 mb-2 block">
        Naam
      </label>
      <TextField id="input_receiver_name" name="receiver_name" v-model="form.receiver_name" :error="validationErrors.receiver_name" />
    </div>
    <div class="col-span-6 lg:col-span-4">
      <label for="input_receiver_email" class="text-sm text-prasset-green-800 mb-2 block">
        E-mailadres
      </label>
      <TextField id="input_receiver_email" name="receiver_email" v-model="form.receiver_email" :error="validationErrors.receiver_email" />
    </div>
  </div>
</template>

<script>
import SelectField from '@/components/field/Select';
import TextareaField from '@/components/field/Textarea';
import TextField from '@/components/field/Text';

export default {
  components: {
    SelectField,
    TextareaField,
    TextField,
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      required: true,
      validator: value => ['create', 'edit'].includes(value),
    },

    original: {
      type: Object,
      default: () => {},
    },

    validationErrors: {
      type: [Array, Object],
      default: () => {},
    },
  },

  computed: {
    variables() {
      if (this.original && this.original.variables) {
        return this.original.variables;
      }

      return [
        'field.gender', 'field.firstname', 'field.insertion',
        'field.lastname', 'field.street', 'field.housenumber',
        'field.postalcode', 'field.city', 'field.emailaddress',
        'field.phonenumber', 'field.remarks', 'form.name',
        'form.description', 'form.values', 'project.name',
        'project.description', 'project.logo', 'preferences',
        'company.name', 'company.logo', 'email.subject',
        'from.email', 'from.name', 'to.email', 'to.name',
      ];
    },
  },

  methods: {
    copyToClipboard(value) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(`{{${value}}}`);
        this.$notify({
          type: 'success',
          text: 'Tekst gekopieërd',
        });
      }
    },
  },
};
</script>
