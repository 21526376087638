<template>
  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <label for="input_name" class="text-sm text-prasset-green-800 mb-2 block">
        Naam
      </label>
      <TextField id="input_name" name="name" v-model="form.name" :error="validationErrors.name" />
    </div>
    <div v-if="mode === 'create'" class="col-span-6">
      <label for="input_preset" class="text-sm text-prasset-green-800 mb-2 block">
        Vooraf ingesteld formulier
      </label>
      <SelectField id="input_preset" name="preset" v-model="form.preset" :value="form.preset" :options="presetOptions" :error="validationErrors.preset" />
    </div>
  </div>
</template>

<script>
import SelectField from '@/components/field/Select';
import TextField from '@/components/field/Text';

export default {
  components: {
    SelectField,
    TextField,
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },

    mode: {
      type: String,
      required: true,
      validator: value => ['create', 'edit'].includes(value),
    },

    original: {
      type: Object,
      default: () => {},
    },

    validationErrors: {
      type: [Array, Object],
      default: () => {},
    },
  },

  computed: {
    presetOptions() {
      return {
        PRESET_CONTACTFORM_SIMPLE: 'Contact formulier',
        PRESET_CONTACTFORM_EXTENDED: 'Contact formulier (uitgebreid)',
        PRESET_SUBSCRIPTION_SIMPLE: 'Inschrijf formulier',
        PRESET_SUBSCRIPTION_EXTENDED: 'Inschrijf formulier (uitgebreid)',
        PRESET_PROJECTINTEREST: 'Projectinteresse formulier',
      };
    },
  },
};
</script>
