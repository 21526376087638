/* eslint-disable */
export default function(ctx) {

  // console.log(ctx.$tuf('actions'));

  return {
    assets: [
      {
        type: "section",
        title: "Basisgegevens"
      },
      {
        label: "Contract type",
        name: "contract_type",
        map: "contract_type",
        type: "select",
        options: {
          RENT: "Verhuur",
          SALE: "Verkoop"
        },
        colspan: 2,
        hint: "Geef aan of het object voor verhuur of verkoop is"
      },
      {
        label: "Bestemming",
        name: "destination",
        map: "destination",
        type: "select",
        options: {
          HOUSING: "Woning",
          RETAIL: "Winkel",
          OFFICE: "Kantoor",
          UTILITY: "Utiliteit",
          OTHER: "Anders",
          UNDEFINED: "Niet gedefinieerd"
        },
        colspan: 2,
        hint: "De bestemming van dit specifieke object"
      },
      {
        label: "Bouwtype",
        name: "buildtype",
        map: "buildtype",
        type: "select",
        options: {
          EXISTING: "Bestaande bouw",
          NEW: "Nieuwbouw"
        },
        colspan: 2,
        hint: "Is er sprake van bestaande bouw, of een nieubouwobject?"
      },
      {
        type: "section",
        title: "Algemeen"
      },
      {
        label: "Naam",
        name: "name",
        map: "name",
        type: "text",
        colspan: 3,
        hint:
          "De naam waarmee het object wordt geïdentificeerd. Gebruik hiervoor een gebruikersvriendelijke naam zoals het adres."
      },
      {
        label: "Bouwnummer",
        name: "assetnumber",
        map: "assetnumber",
        type: "text",
        colspan: 3,
        hint: "Het bouwnummer van het object, indien van toepassing.",
        displayIf: { field: "buildtype", value: "NEW" }
      },
      // {
      //   label: "Asset type",
      //   name: "assettype",
      //   map: "assettype.id",
      //   type: "select",
      //   endpoint: "assettypes",
      //   colspan: 3,
      //   hint: "Iedere asset moet worden ondergebracht in een asset type."
      // },
      {
        label: "Categorieën",
        name: "assetgroups",
        map: "assetgroups",
        type: "multiselect",
        endpoint: "assetgroups",
        colspan: 3,
        hint: "Een object kan aan een onbeperkt aantal categorieën worden gekoppeld."
      },
      {
        label: "Omschrijving",
        name: "description",
        map: "description",
        type: "textarea",
        rows: 3,
        colspan: 6,
        hint: "Korte omschrijving over dit object"
      },
      {
        label: "Publicatie",
        name: "state",
        map: "state",
        type: "select",
        options: {
          IS_DRAFT: "Niet gepubliceerd",
          IS_PUBLISHED: "Gepubliceerd"
        },
        colspan: 3
      },
      {
        label: "Status",
        name: "status",
        map: "status",
        type: "select",
        options: {
          AVAILABLE: "Beschikbaar",
          UNDER_OFFER: "Onder optie",
          UNDER_OPTION: "In optie",
          WITH_RESERVATION: "Gereserveerd",
          SOLD: "Verkocht",
          RENTED: "Verhuurd"
        },
        colspan: 3,
        hint: "De huidige status van dit object"
      },
      {
        type: "section",
        title: "Prijs",
        displayIf: { field: "contract_type" }
      },
      {
        label: "Prijs",
        name: "price",
        map: "price",
        type: "number",
        colspan: 2,
        hint: "De prijs in euro's",
        displayIf: { field: "contract_type" }
      },
      {
        label: "Prijs eenheid",
        name: "price_unit",
        map: "price_unit",
        type: "select",
        options: {
          asset: "Per object",
          m2: "Per vierkante meter"
        },
        displayIf: { field: "contract_type", value: "RENT" },
        colspan: 2,
        hint: "De eenheid waarop de prijs van toepassing is."
      },
      {
        label: "Betalings frequentie",
        name: "contract_payment_frequency",
        map: "contract_payment_frequency",
        type: "select",
        options: {
          monthly: "Maandelijks",
          quarterly: "Kwartaal",
          yearly: "Jaarlijks"
        },
        displayIf: { field: "contract_type", value: "RENT" },
        colspan: 2,
        hint: "In het geval van verhuur, de betalings frequentie."
      },
      {
        label: "Prijs type",
        name: "price_type",
        map: "price_type",
        type: "select",
        options: {
          flat: "Kosten koper",
          inclusive: "Vrij op naam"
        },
        displayIf: { field: "contract_type", value: "SALE" },
        colspan: 4,
        hint:
          "In geval van verkoop, zijn de kosten inclusief in de prijs, of komen die er nog bij (kosten koper)"
      },
      // {
      //   label: "Vaste tarief",
      //   name: "flat_price",
      //   map: "flat_price",
      //   type: "decimal",
      //   colspan: 2,
      //   hint: "De prijs in euro's",
      //   displayIf: [
      //     { field: "contract_type", value: "SALE" },
      //     { field: "price_type", value: "flat" }
      //   ]
      // },
      {
        label: "Servicekosten",
        name: "service_costs",
        map: "service_costs",
        type: "decimal",
        colspan: 2,
        displayIf: { field: "contract_type", value: "RENT" },
        hint:
          "De servicekosten in euro's, per termijn zoals aangegeven in betalingsfrequentie"
      },
      {
        label: "VVE Actief",
        name: "has_owners_association",
        map: "has_owners_association",
        type: "toggle",
        colspan: 2,
        displayIf: { field: "contract_type" },
        hint: "Geef aan of er sprake is van een Vereniging van Eigenaren"
      },
      {
        label: "VVE Contributie",
        name: "contribution_owners_association",
        map: "contribution_owners_association",
        type: "decimal",
        colspan: 2,
        displayIf: [
          { field: "contract_type" },
          { field: "has_owners_association" }
        ],
        hint: "Aangeven in euro's per termijn conform de betalingsfrequentie"
      },
      {
        type: "section",
        title: "Eigenschappen"
      },
      {
        label: "Woonoppervlakte (m2)",
        name: "asset_surface",
        map: "asset_surface",
        type: "number",
        colspan: 2,
        hint: "De totale woonoppervlakte in vierkante meters"
      },
      {
        label: "Inhoud (m3)",
        name: "asset_volume",
        map: "asset_volume",
        type: "number",
        colspan: 2,
        hint: "De gehele inhoud van het object in kubieke meters"
      },
      {
        label: "Kaveloppervlakte",
        name: "lot_surface",
        map: "lot_surface",
        type: "number",
        colspan: 2,
        hint:
          "In geval van een grondgebonden object de grootte van het kavel in vierkante meters"
      },
      {
        label: "Aantal verdiepingen",
        name: "number_of_floors",
        map: "number_of_floors",
        type: "number",
        colspan: 2,
        hint: "Het aantal verdiepingen dat dit object bevat"
      },
      {
        label: "Aantal Kamers",
        name: "number_of_rooms",
        map: "number_of_rooms",
        type: "number",
        colspan: 2,
        hint: "Het aantal kamers dat dit object bevat"
      },
      {
        label: "Aantal Slaapkamers",
        name: "number_of_sleeping_rooms",
        map: "number_of_sleeping_rooms",
        type: "number",
        colspan: 2,
        hint: "Het aantal slaapkamers dat dit object bevat"
      },
      {
        label: "Aantal parkeerplaatsen",
        name: "parking_spaces",
        map: "parking_spaces",
        type: "number",
        colspan: 2,
        hint: "In geval van kavel aantal parkeerplaatsen op eigen grond"
      },
      {
        label: "Energielabel",
        name: "energylabel",
        map: "energylabel",
        type: "select",
        options: {
          "A++": "A++",
          "A+": "A+",
          A: "A",
          B: "B",
          C: "C",
          D: "D",
          E: "A",
          F: "F",
          G: "G",
          NONE: "Geen",
          UNKNOWN: "Onbekend"
        },
        colspan: 3,
        hint: "Het definitieve energielabel behorende bij dit object"
      },
      {
        label: "Bouwjaar",
        name: "year_of_construction",
        map: "year_of_construction",
        type: "number",
        colspan: 3,
        hint: "Het jaar waarin het object is opgeleverd"
      },
      {
        type: "section",
        title: "Bouwinformatie",
        displayIf: { field: "buildtype", value: "NEW" }
      },
      {
        label: "Startdatum bouw",
        name: "constructionstart_date",
        map: "constructionstart_date",
        type: "date",
        colspan: 3,
        displayIf: { field: "buildtype", value: "NEW" },
        hint: "Datum waarop de bouwwerkzaamheden zullen beginnen"
      },
      {
        label: "Opleverdatum",
        name: "delivery_date",
        map: "delivery_date",
        type: "date",
        colspan: 3,
        displayIf: { field: "buildtype", value: "NEW" },
        hint: "Datum waarop oplevering staat gepland"
      },
      {
        label: "Omschrijving startdatum",
        name: "constructionstart_description",
        map: "constructionstart_description",
        type: "textarea",
        rows: 3,
        colspan: 6,
        hint: "Tekstuele onderbouwing over de geplande startdatum",
        displayIf: { field: "buildtype", value: "NEW" }
      },
      {
        label: "Omschrijving opleverdatum",
        name: "delivery_description",
        map: "delivery_description",
        type: "textarea",
        rows: 3,
        colspan: 6,
        hint: "Tekstuele onderbouwing over de geplande opleverdatum",
        displayIf: { field: "buildtype", value: "NEW" }
      },
      {
        type: "section",
        title: "Waardebepaling"
      },
      {
        label: "WOZ Waarde",
        name: "property_valuation_value",
        map: "property_valuation_value",
        type: "text",
        colspan: 3,
        hint: "De meest recente WOZ waarde van dit object"
      },
      {
        label: "WOZ datum",
        name: "property_valuation_date",
        map: "property_valuation_date",
        type: "date",
        colspan: 3,
        hint: "De datum waarop de meest recente WOZ waarde is vastgesteld"
      },
      {
        label: "Taxatie Waarde",
        name: "appraisal_value",
        map: "appraisal_value",
        type: "text",
        colspan: 3,
        hint: "De waarde van dit object in euro volgens de meest recente taxatie"
      },
      {
        label: "Taxatie datum",
        name: "appraisal_date",
        map: "appraisal_date",
        type: "date",
        colspan: 3,
        hint: "De datum waarop de meest recente taxatie is uitgevoerd"
      },
      {
        type: "section",
        title: "Adres"
      },
      {
        label: "Postcode",
        name: "postalcode",
        map: "address.postalcode",
        type: "text",
        colspan: 2
      },
      {
        label: "Huisnummer",
        name: "housenumber",
        map: "address.housenumber",
        type: "number",
        colspan: 1
      },
      {
        label: "Toevoeging",
        name: "housenumber_addition",
        map: "address.housenumber_addition",
        type: "text",
        colspan: 1
      },
      {
        label: "Straatnaam",
        name: "street",
        map: "address.street",
        readonly: true,
        type: "text",
        colspan: 3,
        hint:
          "De straatnaam wordt automatisch ingevuld aan de hand van de ingevoerde combinatie van postcode en huisnummer"
      },
      {
        label: "Plaats",
        name: "city",
        map: "address.city",
        type: "text",
        readonly: true,
        colspan: 3,
        hint:
          "De plaats wordt automatisch ingevuld aan de hand van de ingevoerde combinatie van postcode en huisnummer"
      },
      {
        label: "Provincie",
        name: "province",
        map: "address.province",
        type: "text",
        readonly: true,
        colspan: 3,
        hint:
          "De provincie wordt automatisch ingevuld aan de hand van de ingevoerde combinatie van postcode en huisnummer"
      },
      {
        label: "Land",
        name: "country",
        map: "address.country",
        type: "text",
        readonly: true,
        colspan: 3,
        hint:
          "Het land wordt automatisch ingevuld aan de hand van de ingevoerde combinatie van postcode en huisnummer"
      },
      {
        type: "section",
        title: "Media"
      },
      {
        label: "Hoofdafbeelding",
        name: "mainimage",
        map: "media",
        type: "files",
        colspan: 6,
        hint:
          "Hoofdafbeelding behorende bij dit object. Hier kun je maximaal 1 afbeelding uploaden. De maximale grootte van te uploaden afbeeldingen bedraagt 5MB.",
        options: {
          maxFiles: 1
        }
      },
      {
        label: "Afbeeldingen",
        name: "images",
        map: "media",
        type: "files",
        colspan: 6,
        hint:
          "Afbeeldingen behorende bij dit object. De maximale grootte van te uploaden afbeeldingen bedraagt 5MB per afbeelding."
      },
      {
        label: "Impressies",
        name: "impressions",
        map: "media",
        type: "files",
        colspan: 6,
        hint:
          "Impressies behorende bij dit object. De maximale grootte van te uploaden afbeeldingen bedraagt 5MB per afbeelding.."
      }
    ],
    assetgroups: [
      {
        type: "section",
        title: "Algemeen"
      },
      {
        label: "Naam",
        name: "name",
        map: "name",
        type: "text",
        colspan: 4,
        hint: "De naam waarmee deze groep kan worden geïdentificeerd."
      },
      {
        label: "Omschrijving",
        name: "description",
        map: "description",
        type: "text",
        colspan: 4,
        hint: "Korte omschrijving van deze groep"
      },
      {
        label: "Bovenliggende categorie",
        name: "parent",
        map: "parent.id",
        type: "select",
        endpoint: "assetgroups",
        colspan: 4,
        hint:
          "Selecteren indien deze groep onder een andere groep valt. Bijvoorbeeld `Verdieping 1` onder `Gebouw A`"
      }
    ],
    assettypes: [
      {
        type: "section",
        title: "Algemeen"
      },
      {
        label: "Naam",
        name: "name",
        map: "name",
        type: "text",
        colspan: 5,
        hint: "De naam van dit type"
      },
      {
        label: "Omschrijving",
        name: "description",
        map: "description",
        type: "textarea",
        colspan: 5,
        hint: "Korte omschrijving over dit object"
      },
      {
        label: "Uitgebreide omschrijving",
        name: "extended_description",
        map: "custom_fields.extended_description",
        type: "html",
        colspan: 5,
        hint: "Uitgebreide omschrijving over dit object"
      },
      {
        type: "section",
        title: "Media"
      },
      {
        label: "Hoofdafbeelding",
        name: "mainimage",
        map: "media",
        type: "files",
        colspan: 5,
        hint: "Hoofdafbeelding behorende bij dit object"
      },
      {
        label: "Afbeeldingen",
        name: "images",
        map: "media",
        type: "files",
        colspan: 5,
        hint: "Afbeeldingen behorende bij dit object",
        options: {
          maxFiles: 1
        }
      },
      {
        label: "Impressies",
        name: "impressions",
        map: "media",
        type: "files",
        colspan: 5,
        hint: "Impressies behorende bij dit object"
      }
    ]
  };
}
